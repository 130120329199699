import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from '../../components/ui/wrapper'
import SectionTitle from '../../components/ui/section-title'
import Tabs, {
    TabHeader,
    NavItem,
    TabContent,
    TabPane,
} from '../../components/ui/tabs/layout-one'
import ForumsPane from './forums-pane'
import { TabWrapper } from './forums-area.style'
import { useIntl } from 'gatsby-plugin-react-intl'

const ForumsArea = ({ sectionStyles, sectionTitleStyle }) => {
    const intl = useIntl()

    const forumsData = useStaticQuery(graphql`
        query ForumsQuery {
            forumsJson {
                categories {
                    id
                }
                items {
                    id
                    title
                    link
                    category
                    image {
                        childImageSharp {
                            fluid(maxWidth: 480, maxHeight: 338, quality: 100) {
                                presentationWidth
                                presentationHeight
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }
    `)

    const secdata = forumsData.forumsJson
    const { items, categories } = secdata

    return (
        <TabWrapper id="section-forums">
            <Container>
                <Row>
                    <Col lg={12}>
                        <SectionTitle
                            {...sectionTitleStyle}
                            subtitle={intl.formatMessage({
                                id: 'area.forums.subtitle',
                            })}
                            title={intl.formatMessage({
                                id: 'area.forums.title',
                            })}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <Tabs id="forums-tab" defaultActiveKey="gaming">
                            <TabHeader>
                                {categories.map(category => {
                                    return (
                                        <NavItem
                                            key={category.id}
                                            eventKey={category.id}
                                        >
                                            {intl.formatMessage({
                                                id: `area.forums.categories.${category.id}`,
                                            })}
                                        </NavItem>
                                    )
                                })}
                            </TabHeader>
                            <TabContent>
                                {categories.map(category => {
                                    return (
                                        <TabPane
                                            eventKey={category.id}
                                            key={category.id}
                                        >
                                            <ForumsPane
                                                category={category.id}
                                                items={items.filter(
                                                    item =>
                                                        item.category ===
                                                        category.id
                                                )}
                                            />
                                        </TabPane>
                                    )
                                })}
                            </TabContent>
                        </Tabs>
                    </Col>
                </Row>
            </Container>
        </TabWrapper>
    )
}

ForumsArea.propTypes = {
    sectionTitleStyle: PropTypes.object,
}

ForumsArea.defaultProps = {
    sectionTitleStyle: {
        mb: '60px',
        responsive: {
            medium: {
                mb: '50px',
            },
        },
    },
}

export default ForumsArea
