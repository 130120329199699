import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from '../../../components/ui/wrapper'
import Frame from '../../../components/frame'
import { ForumsPaneWrap } from './forums-pane.style'

const ForumsPane = ({ category, items, serviceBoxStyle }) => {
    return (
        <ForumsPaneWrap>
            <Row>
                {items &&
                    items.map(forum => {
                        return (
                            <Col lg={4} md={6} key={forum.id}>
                                <Frame content={forum} />
                            </Col>
                        )
                    })}
            </Row>
        </ForumsPaneWrap>
    )
}

ForumsPane.propTypes = {
    serviceBoxStyle: PropTypes.object,
}

ForumsPane.defaultProps = {
    serviceBoxStyle: {
        boxStyle: {
            responsive: {
                medium: {
                    mb: '42px',
                },
            },
        },
    },
}

export default ForumsPane
