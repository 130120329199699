import styled from 'styled-components'
import { device } from '../../theme'

export const HeroWrapper = styled.section`
    overflow: hidden;
    background-color: #f4efe9;
    position: relative;
    .container {
        display: flex;
        height: 662px;
        @media ${device.xsmall} {
            height: unset;
        }
    }
`

export const HeroImageBackground = styled.div`
    position: absolute;
    right: 0;
    pointer-events: none;
    text-align: right;
    height: 100%;
    width: 100%;
    @media ${device.xxlargeOnly} {
        right: 10%;
    }
    @media ${device.medium} {
        .worldRotate {
            animation: none;
        }
    }
    @media ${device.xxsmall} {
        .worldRotate {
            max-width: 550px;
        }
    }
`

export const HeroContent = styled.div`
    max-width: 680px;
    width: 100%;
    margin-left: auto;
    @media ${device.medium} {
        padding-left: 0;
    }
    @media ${device.xsmall} {
        padding-top: 90px;
        padding-bottom: 90px;
    }
`

export const HeroImagesWrap = styled.div`
    position: relative;
    padding-top: 80px;
    @media ${device.small} {
        padding-top: 60px;
    }
`

export const HeroImageOne = styled.figure`
    position: absolute;
    z-index: 1;
    bottom: 0;
    width: 100%;
    height: 100%;
    @media ${device.large} {
        height: auto;
    }
`

export const HeroImageTwo = styled.figure`
    position: relative;
    z-index: 2;
    display: flex;
    visibility: hidden;
    img {
        margin: 0 auto;
    }
`

export const TypedTextWrap = styled.h3`
    line-height: 1.34;
    word-wrap: break-word;
    font-weight: 700;
    margin-bottom: 30px;
    @media ${device.medium} {
        font-size: 40px;
    }
    @media ${device.small} {
        font-size: 35px;
    }
    span {
        &:not(.not-typical) {
            color: ${props => props.theme.colors.themeColor};
            font-style: italic;
            &:empty:before {
                content: "\\200b";
            }
        }
    }
`
