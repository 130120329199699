import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from '../../components/ui/wrapper'
import SectionTitle from '../../components/ui/section-title'
import BoxIcon from '../../components/box-icon/layout-two'
import { ServicesWrapper } from './extensions-area.style'
import { useIntl } from 'gatsby-plugin-react-intl'

const ExtensionsArea = ({ sectionTitleStyle }) => {
    const intl = useIntl()
    const extensionsData = useStaticQuery(graphql`
        query ExtensionsQuery {
            extensionsJson {
                extensions {
                    id
                    path
                    icon {
                        fa
                        svg {
                            publicURL
                        }
                    }
                }
            }
        }
    `)
    const secData = extensionsData.extensionsJson
    const extensions = secData.extensions
    return (
        <ServicesWrapper id="section-extensions">
            <Container>
                <Row>
                    <Col lg={12}>
                        <SectionTitle
                            {...sectionTitleStyle}
                            title={intl.formatMessage({
                                id: 'area.extensions.title',
                            })}
                            subtitle={intl.formatMessage({
                                id: 'area.extensions.subtitle',
                            })}
                        />
                    </Col>
                </Row>
                <Row>
                    {extensions.map(ext => {
                        return (
                            <Col lg={4} md={6} key={ext.id}>
                                <BoxIcon
                                    id={ext.id}
                                    title={intl.formatMessage({
                                        id: `area.extensions.${ext.id}.title`,
                                    })}
                                    desc={intl.formatMessage({
                                        id: `area.extensions.${ext.id}.desc`,
                                    })}
                                    icon={ext.icon}
                                    path={ext.path}
                                />
                            </Col>
                        )
                    })}
                </Row>
            </Container>
        </ServicesWrapper>
    )
}

ExtensionsArea.propTypes = {
    sectionTitleStyle: PropTypes.object,
    buttonOneStyle: PropTypes.object,
    buttonTwoStyle: PropTypes.object,
}

ExtensionsArea.defaultProps = {
    sectionTitleStyle: {
        mb: '40px',
        responsive: {
            small: {
                mb: '30px',
            },
        },
    },
}

export default ExtensionsArea
