import React from 'react'
import PropTypes from 'prop-types'
import ReactVivus from 'react-vivus'
import Button from '../../ui/button'
import { useIntl } from 'gatsby-plugin-react-intl'
import {
    BoxIconWrap,
    BoxIconInner,
    BoxIconImg,
    BoxIconContent,
    BoxIcontitle,
    BoxIconText,
    BoxIconBtn,
} from './box-icon.style'

const BoxIcon = props => {
    const intl = useIntl()
    const { id, icon, title, desc, path, ...restProps } = props
    const { boxStyles, titleStyles, textStyles, buttonStyles } = restProps
    const iconUrl = (icon && icon.svg && icon.svg.publicURL) || icon
    const faIcon = icon && icon.fa

    return (
        <BoxIconWrap>
            <BoxIconInner {...boxStyles}>
                <BoxIconImg>
                    {(faIcon && <i className={faIcon}></i>) ||
                        (iconUrl && (
                            <ReactVivus
                                id={`svg-${id}`}
                                option={{
                                    file: iconUrl,
                                    type: 'oneByOne',
                                    animTimingFunction: 'EASE',
                                    duration: 250,
                                }}
                            />
                        ))}
                </BoxIconImg>
                <BoxIconContent>
                    {title && (
                        <BoxIcontitle {...titleStyles}>{title}</BoxIcontitle>
                    )}
                    {desc && <BoxIconText {...textStyles}>{desc}</BoxIconText>}
                    {path && (
                        <BoxIconBtn>
                            <Button
                                to={path}
                                {...buttonStyles}
                                icon="far fa-long-arrow-right"
                            >
                                {intl.formatMessage({
                                    id: 'area.extensions.more',
                                })}
                            </Button>
                        </BoxIconBtn>
                    )}
                </BoxIconContent>
            </BoxIconInner>
        </BoxIconWrap>
    )
}

BoxIcon.propTypes = {
    heading: PropTypes.string,
    desc: PropTypes.string,
    path: PropTypes.string,
    boxStyles: PropTypes.object,
    titleStyles: PropTypes.object,
    textStyles: PropTypes.object,
    buttonStyles: PropTypes.object,
}

BoxIcon.defaultProps = {
    buttonStyles: {
        varient: 'texted',
        fontWeight: 400,
        hover: 'false',
        icondistance: '8px',
    },
    path: '/',
}

export default BoxIcon
