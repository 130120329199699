import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import Button from '../ui/button'
import { useIntl } from 'gatsby-plugin-react-intl'

import {
    PreviewWrap,
    PreviewInner,
    FrameScreen,
    Dots,
    PreviewThumb,
    PreviewOverlay,
    PreviewLink,
    ButtonWrap,
    PreviewInfo,
    HeadingWrap,
} from './frame.style'

const Frame = ({ content }) => {
    const intl = useIntl()
    const { link, title, image } = content

    return (
        <PreviewWrap>
            <PreviewInner>
                <FrameScreen>
                    <PreviewLink path={link}>{title}</PreviewLink>
                    <Dots>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                    </Dots>
                    <PreviewThumb>
                        {image && (
                            <Img
                                className="img-fluid"
                                fluid={image.childImageSharp.fluid}
                                alt={title}
                            />
                        )}
                        <PreviewOverlay>
                            <ButtonWrap>
                                <Button to={link} skin="light">
                                    {intl.formatMessage({
                                        id: 'area.forums.visit',
                                    })}
                                </Button>
                            </ButtonWrap>
                        </PreviewOverlay>
                    </PreviewThumb>
                    <PreviewInfo>
                        {title && <HeadingWrap>{title}</HeadingWrap>}
                    </PreviewInfo>
                </FrameScreen>
            </PreviewInner>
        </PreviewWrap>
    )
}

Frame.propTypes = {
    path: PropTypes.string,
    title: PropTypes.string,
    image: PropTypes.object,
}

export default Frame
