import React from 'react'
import { useIntl } from 'gatsby-plugin-react-intl'
import parse from 'html-react-parser'
import SEO from '../components/seo'
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-custom'
import Footer from '../containers/layout/footer/footer-custom'
import Hero from '../containers/hero-area'
import ForumsArea from '../containers/forums-area'
import ExtensionsArea from '../containers/extensions-area'
import CounterThree from '../containers/elements/counters/section-three'

const IndexPage = ({ location }) => {
    const intl = useIntl()
    const t = id => intl.formatMessage({ id: id })
    return (
        <Layout location={location}>
            <SEO />
            <Header />
            <main className="site-wrapper-reveal">
                <Hero />
                <ForumsArea />
                <ExtensionsArea />
                <CounterThree
                    counter0={21}
                    text0={t('area.counter.forums')}
                    heading0={parse(t('area.counter.forumsHeading'))}
                    counter1={154333}
                    text1={t('area.counter.members')}
                    counter2={276890}
                    text2={t('area.counter.threads')}
                    counter3={2924788}
                    text3={t('area.counter.posts')}
                    counter4={92913}
                    text4={t('area.counter.newsletter')}
                />
            </main>
            <Footer />
        </Layout>
    )
}

export default IndexPage
