import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import parse from 'html-react-parser'
import Heading from '../../components/ui/heading'
import Button from '../../components/ui/button'
import { Container, Row, Col } from '../../components/ui/wrapper'
import {
    HeroWrapper,
    HeroContent,
    TypedTextWrap,
    HeroImageBackground
} from './hero-area.style'
import { useIntl } from 'gatsby-plugin-react-intl'
import Typed from 'react-typed'

const HeroArea = props => {
    const intl = useIntl()
    const t = id => intl.formatMessage({ id: id })

    const heroData = useStaticQuery(graphql`
        query CustomHeroQuery {
            indexMainJson(id: { eq: "hero-content" }) {
                path
                imageOne {
                    childImageSharp {
                        fluid(base64Width: 779, quality: 100) {
                            base64
                        }
                    }
                }
            }
        }
    `)

    const { path, imageOne} = heroData.indexMainJson
    const {
        subtitleStyle,
        // titleStyle
    } = props
    return (
        <HeroWrapper>
            <Container>
                <Row alignitems="center">
                    <Col lg={9} md={10}>
                        <HeroContent>
                            <Heading {...subtitleStyle}>
                                {parse(t('area.hero.subtitle'))}
                            </Heading>
                            {/*<Heading {...titleStyle}>{parse(intl.formatMessage({ id: "area.hero.title" }))}</Heading>*/}
                            <TypedTextWrap>
                                <span className="not-typical">
                                    {parse(t('area.hero.title_1'))}{' '}
                                </span>
                                <Typed
                                    strings={[
                                        t('area.hero.words.0'),
                                        t('area.hero.words.1'),
                                        t('area.hero.words.2'),
                                        t('area.hero.words.3'),
                                        t('area.hero.words.4'),
                                    ]}
                                    typeSpeed={40}
                                    backSpeed={50}
                                    loop
                                />
                                <span className="not-typical">
                                    {parse(t('area.hero.title_2'))}
                                </span>
                            </TypedTextWrap>
                            {path && (
                                <Button hover="2" to={path}>
                                    {t('area.hero.more')}
                                </Button>
                            )}
                        </HeroContent>
                    </Col>
                    <HeroImageBackground>
                        <img
                            className="worldRotate"
                            src={imageOne.childImageSharp.fluid.base64}
                            alt="Hero Pattern"
                        />
                    </HeroImageBackground>
                </Row>
            </Container>
        </HeroWrapper>
    )
}

HeroArea.defaultProps = {
    subtitleStyle: {
        as: 'h6',
        texttransform: 'uppercase',
        letterspacing: '3px',
        fontWeight: 700,
        fontSize: '14px',
        mb: '20px',
    },
    titleStyle: {
        as: 'h3',
        mb: '30px',
        child: {
            color: 'primary',
        },
    },
}

export default HeroArea
